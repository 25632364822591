import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import * as styles from "./header.module.css"

const NavLink = (props) => (
  <li className={styles.navLink}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            githubUrl
          }
        }
      }
    `
  )
  return (
    <header className={styles.header}>
      <Link to="/">
        <h2 className={styles.title}>{data.site.siteMetadata.title}</h2>
      </Link>
      <ul className={styles.navbar}>
        <NavLink to={data.site.siteMetadata.githubUrl}>GitHub</NavLink>
        <NavLink to="/blog">Blog</NavLink>
      </ul>
    </header>
  )
}
